<template>
  <div />
</template>
<script>
export default {
  // name: 'LogIn',
  data: () => ({

  }),
  mounted(){
    let checkCount = 0;
    let intervalObj = setInterval(()=>{
      if(checkCount>50){
        window.auth={};
        window.auth.isAuthenticated = true;
        // return;
      }
      checkCount++;
      console.log(checkCount);

      if(window.auth?.isAuthenticated){
        clearInterval(intervalObj)


        let redirectPage = this.getQueryPath();
        debugger;
        this.$router.push({
          path: redirectPage.path,query:redirectPage?.query
        });
      }

    },100)

  },
  methods: {
    getQueryPath(){
      if(this.$route.query.to!=null ){
        // let params = '';
        // if(this.$route.query.query !=null){
        //   params = this.$route.query.
        // }
        return {path:this.$route.query.to.path,query:this.$route.query.to.query};
      }else{
        return {path:'/'};
      }

    },
  }
}
</script>
<style lang="">

</style>

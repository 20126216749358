<template>
  <div style="width:100%;height:100%;">
    <!-- <span>{{ config }}</span> -->
    <!--    <v-btn @click="changeData">-->
    <!--      Data Sync-->
    <!--    </v-btn>-->
    <span>{{ receiveData }}</span>
    <v-chart
      ref="eChart"
      class="chart"
      :option="option"
      autoresize
    />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import Widget from "../../common-lib/widgets/Widget.vue";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  components: {
    VChart
  },
  mixins:[Widget],
  provide: {
    [THEME_KEY]: "dark"
  },
  data() {
    return {
      receiveData:"",
      option: {
        title: {
          text: "Traffic Sources",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a}     {b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [
            "Direct",
            "Email",
            "Ad Networks",
            "Video Ads",
            "Search Engines"
          ]
        },
        series: [
          {
            name: "Traffic Sources",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              { value: 335, name: "Direct" },
              { value: 310, name: "Email" },
              { value: 234, name: "Ad Networks" },
              { value: 135, name: "Video Ads" },
              { value: 1548, name: "Search Engines" }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      },

    };
  },

  methods:{
    // 아래 함수만 구현 하면 됨
    // Override
    receiveConfig(config){
      console.log("receive Config custom");
      this.option.series[0].data=[
        { value: Math.random()*1000, name: "Direct" },
        { value: Math.random()*1000, name: "Email" },
        { value: Math.random()*1000, name: "Ad Networks" },
        { value: Math.random()*1000, name: "Video Ads" },
        { value: Math.random()*1000, name: "Search Engines" }
      ]
      this.option = {...this.option}

    },
    receiveSyncData(data){
      console.log("receiveSyncData",data);
      this.receiveData = data;
    },
    changeData(){
      let data = {date:new Date(),message:"my sync data"};
      this.sendSyncData(data);
    }
  }
};
</script>
<style>
.chart {
}
html,body{
  height: 100%;
  width: 100%;
}
</style>

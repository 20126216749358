<template>
  <v-container>
    <v-text-field
      v-model="config.name"
      :counter="10"
      label="Name"
    />

    <v-text-field
      v-model="config.email"
      label="E-mail"
    />

    <v-select
      v-model="config.person"
      :items="items"
      label="Person"
    />
    <v-select
      v-model="config.address"
      :items="items"
      label="Address"
    />

    <v-checkbox
      v-model="config.agree"
      label="Do you agree?"
    />
  </v-container>
</template>

<script>
import WidgetConfig from "@/common-lib/widgets/WidgetConfig"

export default {
  components: {
  },
  mixins:[WidgetConfig],

  data() {
    return {
      config:{name:'name',email:'email',person:'james',agree:false},
      items:["james","alias","tom"]
    };
  },
  mounted(){
    this.config=this.getConfig();
    console.log('config',this.config)
  },
  methods:{
    requestConfig() {
      this.saveConfig(this.config);
    }
  }

};
</script>
<style>

html,body{
  height: 100%;
  width: 100%;
}
.W100{
  width:100%;
}
</style>

<template>
  <div />
</template>

<script>
import { use } from "echarts/core";
import MessageHandler from "@/common-lib/MessageHandler";
import Vue from 'vue'
import DatetimePicker from 'vuetify-datetime-picker'


export default {
  components: {

  },

  data() {
    return {
      name:'',
      customConfig:{},
      targetName:''
    };
  },
  activated(){

  },
  mounted(){
    this.name = this.$route.query.name;
    this.targetName = this.$route.query.targetName
    console.log('customconfig',this.$route.query.customConfig)
    if(this.$route.query.customConfig!=null && this.$route.query.customConfig!='undefined'){
      this.customConfig = JSON.parse(this.$route.query.customConfig);
    }
  },
  created() {
    window.addEventListener( 'message', this.receiveMessageFromParent );
  },
  beforeDestroy(){
    window.removeEventListener("message",this.receiveMessageFromParent);
  },
  methods:{
    receiveMessageFromParent(e){
      if (e.data == null) return;
      if (e.data.cmd == 'getCustomConfig' && e.data.name==this.targetName) {
        this.requestConfig();
      }
    },
    sendMessage(message){
      window.parent.postMessage( message, '*' );
    },
    requestConfig(){

    },
    saveConfig(config){
      this.sendMessage({cmd:'saveConfig',name:this.targetName,data:{customConfig:config,name:this.name}})
    },
    getConfig(){
      return this.customConfig;
    },
  },

};
</script>
<style >

html,body{
  height: 100%;
  width: 100%;
}
.W100{
  width:100%;
}
/* .v-card__text{
  height: 455px;
  overflow: auto;
} */


  .my-card >.v-card__text{
    height: 600px !important;
    overflow: auto !important;
    background: white;
    margin: 10px 30px;
    padding: 30px;
    width: calc(100% - 60px);
    border-radius: 10px;
  }
/* 스크롤바 설정*/
.my-card >.v-card__text::-webkit-scrollbar{
  width: 6px;
}

/* 스크롤바 막대 설정*/
.my-card>.v-card__text::-webkit-scrollbar-thumb{
  height: 17%;
  background-color: #dcdfe6;
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
.my-card>.v-card__text::-webkit-scrollbar-track{
  background-color: rgba(0,0,0,0);
}


  .my-card{
    background: #f2f6fc !important;
    border-radius: 20px !important;
  }
  .my-card > .v-card__title{
    color:#3d8df5;
  }

  .widget-config .theme--light.v-tabs > .v-tabs-bar {
    background-color: #F8FAFD;
  }
  .widget-config .v-tabs{
        flex: 1 1 auto;
    width: calc(100% + 60px);
    margin-left: -30px;
  }
  .widget-config .v-tab{
    border-bottom: solid 1px #dcdfe6;
  }
  .widget-config .v-tab:not(.v-tab ~ .v-tab){
     border-left: 0 !important;
    /* border-right: 0 !important; */
    border-top:0 !important;
    margin-left:0px !important;
  }
  .widget-config .v-slide-group__wrapper:after{
    position: absolute;
    content:"";
    width:100%;
    bottom: 0;
    left:0;
    border-bottom: 1px solid #dcdfe6;

  }
  .widget-config .v-tab--active{
    background-color: white;
    border-left: solid 1px #dcdfe6;
    border-right: solid 1px #dcdfe6;
    border-top:solid 1px #dcdfe6;
  }
  .widget-config .container{
    margin-top: -30px;
    padding:0;

  }
  .widget-config .row-align{
    display:flex
  }
  .widget-config .col-align{
    padding-left:0;
  }
  /* .widget-config .v-tabs{
    margin-left:-70px;
  }
  .widget-config .v-input__slot{
    margin-left:10px;
  }
    .widget-config .v-item-group {
        padding-left: 15px !important;
        width: 100%;
  } */

</style>
